import { createGlobalStyle } from "styled-components"

// Utils
import breakpoint from "../../utils/breakpoints/"
import { colors, fonts } from "../../utils/variables/"


const GlobalStyles = createGlobalStyle`

  .no-scroll {
    overflow: hidden!important;
  }

  body {
    color: ${colors.charcoal};
    font-family: ${fonts.primary};
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6em;

    ${breakpoint.medium`
      font-size: 21px;
    `}

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-family: ${fonts.primary};
      font-weight: 700;
      line-height: 1.2em;
    }

    h1 {
      font-size: 36px;
      font-weight: 700;
      letter-spacing: -1px;
      color: ${colors.charcoal};

      ${breakpoint.medium`
        font-size: 46px;
      `}
      
    }

    h2 {
      font-size: 32px;
      font-weight: 700;

      ${breakpoint.medium`
        font-size: 42px;
      `}
    }

    h3 {
      font-size: 17px;
      font-weight: 700;

      ${breakpoint.medium`
        font-size: 21px;
      `}
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    button {
      padding: 0;
      margin: 0;
      border: 0;
      font-family: inherit;
      font-size: inherit;
      font-weight: 400;
      color: inherit;
      background: none;
      box-shadow: 0;
    }

    img,
    svg {
      max-width: 100%;
      height: auto;
      display: inline-block;
    }

	sup {
		font-size: 0.6rem;
		vertical-align: super;
		font-family: serif;
		position: relative;
		top: -0.3em;
		left: 0.1em;
	}
    
    select { 
      font-family: ${fonts.primary};
    }

  .grecaptcha-badge { visibility: hidden; }

  }
    

`

export default GlobalStyles
