import React from "react"
import { StaticImage } from "gatsby-plugin-image"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "../utils/breakpoints/"
import { colors } from "../utils/variables/"

// Components
import Container from "./container"

const StyledContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1100px;
`

const StyledFooter = styled.footer`
  padding: 20px 0;
  background-color: ${colors.charcoal};
  color: ${colors.white};

  ${breakpoint.medium`
    padding: 54px 0;
  `}

  .msg-error {
      font-size: 17px;
      font-weight: 500;
      color: ${colors.orange};
      padding-left: 12px!important;
  }

    
  .footer__column {
    width: 100%;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }


    ${breakpoint.medium`
		width: 70%;

      &--logo {
         width: 250px;
		margin-top: 5px;
		margin-left: 25px;
      }

      &--sitemap {
        order: 0;
      }
            
    `}

    .title {
      color: ${colors.purpleLight};
      margin-bottom: 10px;
    }

    .footer__copy {
      margin-top: 1rem;
      font-size: 0.7rem;
      line-height: 1.1rem;
    }

  }

	.footer__column--sitemap a:hover {
		color: ${colors.orange};
	}
  
  ul {
 	display: block;

	  ${breakpoint.medium`
		display: inline-flex;
	  `}

  }
  
  li {
  		font-size: 17px;
		margin-right: 50px;
  }

`
class Footer extends React.Component {
  render = () => (
    <StyledFooter>
      <StyledContainer>
        <div className="footer__column footer__column--logo">
          <a href="https://www.visbymedical.com/" target="_blank" rel="noopener noreferrer" aria-label="Visit Visby Medical website">
            <StaticImage
              src="../assets/icons/logo-tm-white.png"
              width={220}
              alt="logo"
            /></a>
        </div>
        <div className="footer__column footer__column--sitemap">
          <ul>
            <li key="item1">
              <a href="https://www.visbymedical.com/" target="_blank" rel="noopener noreferrer">Home</a>
            </li>
            <li key="item2">
              <a href="https://www.visbymedical.com/respiratory-health-test/" target="_blank" rel="noopener noreferrer">Respiratory Health Test</a>
            </li>
            <li key="item3">
              <a href="https://www.visbymedical.com/faq/" target="_blank" rel="noopener noreferrer">FAQ</a>
            </li>
            <li key="item4">
              <a href="https://www.visbymedical.com/legal/" target="_blank" rel="noopener noreferrer">Legal</a>
            </li>
            <li key="item5">
              <a href="https://www.visbymedical.com/contact/" target="_blank" rel="noopener noreferrer">Contact</a>
            </li>
          </ul>
          <p className="footer__copy">©{new Date().getFullYear()} Visby Medical, Inc. All&nbsp;rights&nbsp;reserved.
            Visby&nbsp;Medical and the Visby&nbsp;Medical logo are
            registered trademarks of Visby&nbsp;Medical, Inc.</p>
        </div>
      </StyledContainer>
    </StyledFooter>
  )
}

export default Footer
