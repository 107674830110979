import React from "react"

// Libraries
import AOS from "aos"

// Styles
import "../assets/globalStyles/reset.css"
import GlobalStyles from "../assets/globalStyles"
import "aos/dist/aos.css"

// Components
import Banner from "../components/banner"
import Footer from "../components/footer"
import FooterRespiratory from "../components/footer-respiratory"

class Layout extends React.Component {
  componentDidMount = () => {
    AOS.init({
      duration: 600,
      once: true,
    })
  }

  render = props => (
    <React.Fragment>
      <GlobalStyles />
      {this.props.children}
      { ( this.props.location.pathname !== "/respiratory-health-test/" &&  this.props.location.pathname !== "/respiratory-health-test-thank-you/") ?
      <Banner />
      : null
      }
      { ( this.props.location.pathname === "/respiratory-health-test/" ||  this.props.location.pathname === "/respiratory-health-test-thank-you/") ?
      <FooterRespiratory />
      :
      <Footer />
     }
    </React.Fragment>
  )
}

export default Layout
