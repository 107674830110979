import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "../utils/breakpoints/"

// Components
import Container from "./container"


const StyledContainer = styled(Container)`
  max-width: 1150px;
  padding: 10px 0 40px;;
  overflow: hidden;

  ${breakpoint.small`
    padding: 20px 20px 100px;
  `}
`

const StyledBanner = styled.section`
  margin: 0 auto;
  
  .center_image {
  	width: 100%; 
  }

	.footnote {
    margin-top: 10px;
		font-size: 12px;
		line-height: 1.4em;
    text-align: center;
	}

  `

const Banner = () => {
  return (
    <StyledContainer>
      <StyledBanner data-aos="fade-up" >
        <StaticImage
          src="../assets/images/request-a-demo/VisbyMedical-PCR-STI-POC.jpg"
          width={1100}
          alt="A physician showing the device to a patient."
        />
			<p className="footnote">All photos are stock photos, used for illustrative purposes only. Posed by models.</p>
      </StyledBanner>
    </StyledContainer>
  )
}

export default Banner
